import React from 'react';
import effectRipple from 'js-effect-ripple';
import currencyFormatter from 'currency-formatter';
import {Accordion, Card, useAccordionToggle} from 'react-bootstrap';
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// scss
import '../scss/CartStyle.scss';

function CustomToggle ({children,eventKey}){
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        console.log('totally custom!'),
    );
    return(
        <div onClick={decoratedOnClick}>
            {children}
        </div>
    )
}
class CartComponent extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            pullUp:true
        }
    }
    handlePullUp = (e) => {
        this.setState({
            pullUp:!this.state.pullUp
        })
    }
    render(){
        let waLinkHeader = "https://api.whatsapp.com/send?phone=6281312034575&text=Gw%20mo%20pesen%0A----------------------------------%0A"
        let waLinkFooter = "%0ATotal%20jadi%20"+currencyFormatter.format(this.props.total,{'locale':'id_ID'})+"%20kan?"
        let waLink = waLinkHeader+this.props.waLink+waLinkFooter
        return (
            <div>
                <Accordion defaultActiveKey="0" className="w-100 shadow-lg cart">
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="0">
                                <div onClick={(e)=>this.handlePullUp(e)} data-toggle="collapse" data-target="#cartList" aria-expanded={this.state.pullUp} aria-controls="cartList" className="circle-open shadow">
                                    <FontAwesomeIcon icon={faChevronUp} className={!this.state.pullUp?'up':'down'} />
                                </div>
                            </CustomToggle>                 
                            <h4 className="mb-0">Cart</h4>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                { this.props.cart.map((item,i)=>(
                                    <div key={i} className="d-flex flex-row p-2 animate__animated animate__slideInUp animate__faster">
                                        <img src={item.image} alt="Gambar Product" className="mr-2 rounded" style={{ width:"50px",height:"50px",objectFit:"cover" }} />
                                        <div className="d-flex flex-column">
                                            <b>{item.product}</b>
                                            <span style={{ fontSize:"12px" }}>{currencyFormatter.format(item.price,{locale:'id_ID'})}</span>
                                        </div>
                                        <div className="ml-auto mr-3 align-middle">
                                            x {item.numProduct}
                                        </div>
                                        <div>
                                            {currencyFormatter.format((item.numProduct*item.price),{locale:'id_ID'})}
                                        </div>
                                    </div>
                                )) }
                                {this.props.cart.length==0?(
                                    <div style={{ color:"#777" }}>Belum menambahkan produk</div>
                                ):''}
                                {this.props.cart.length>0?(
                                    <div className="d-flex flex-row h-100 align-items-center">
                                        <a href={waLink} className="btn btn-success mt-2">Kirim</a>
                                        <div className="ml-auto">Total : {currencyFormatter.format(this.props.total,{'locale':'id_ID'})}</div>
                                    </div>
                                ):''}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>  
                </Accordion>
            </div>
        )
    }
}

export default CartComponent;