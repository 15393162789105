import React from 'react';
import axios from 'axios';
import effectRipple from 'js-effect-ripple';
import Swal from 'sweetalert2';
import currencyFormatter from 'currency-formatter';

// Component
import CartComponent from '../Component/CartComponent';

// Style
import '../scss/HomeStyle.scss';

function ProductItem(props){
    return(
        <div className={"rounded animate__animated animate__backInDown animate_delay-"+(props.indexNum*250)+"ms animate__fast"}>
            <div className={"product-list col-12 border mb-2 p-3 rounded"}>
                <div className="d-flex flex-row inner-product-list">
                    <div className="wrap-img mr-3">
                        <img src={props.image} alt="Gambar Product" className="product-image-list rounded" />
                    </div>
                    <div className="d-flex flex-column">
                        <h5 className="mb-0">{props.name}</h5>
                        <small className="mb-1" style={{ color:"#3ac78e" }}><b>{props.price}</b></small>
                        <p className="mb-0">{props.description}</p>
                    </div>
                </div>
                <div className="wrap-ripple rounded" onClick={(e)=>props.productHanldeClick(e)}></div>
            </div>
        </div>
    )
}

class HomePage extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            products:[],
            cart:[],
            total:0,
            waLink:""
            
        }
    }
    componentDidMount(){
        this.getData()
    }
    
    getData = async () => {
        await axios.get('/product')
            .then(res=>res.data)
            .then(product=>{
                this.setState({...this.setState,products:product.data})
                console.log(this.state)
            })
    }
    productHanldeClick = async (e, product) => {
        try{
            effectRipple(e, {bgc: 'rgba(0, 100, 150, 0.3)'});
            const { value: numProduct } = await Swal.fire({
                title:'Jumlah Pesanan',
                input:'number',
                inputPlaceholder: 'Masukan jumlah pesanan',
                allowOutsideClick:false,
                allowEscapeKey:false,
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                      if (value !== '') {
                        resolve()
                      } else {
                        resolve('Isi atuh bambang :)')
                      }
                    })
                  }
            });
            let cart = {
                id:product.id,
                image:product.ProductImages[0].path,
                product:product.name,
                numProduct: numProduct,
                price:product.price
            }
            let checkcart = this.state.cart.some(item=>cart.id===item.id);
            let carts = [...this.state.cart];

            if(!checkcart){
                carts.push(cart)
            }else{
                carts.map(item=>item.id === cart.id? item.numProduct = numProduct:item.numProduct)
            }
            let total = 0;
            let waLink = "";
            await carts.forEach(item=>{
                let totalItem = item.numProduct*item.price
                total += totalItem
                let productName = item.product.split(' ').join('%20')
                let itemPriceFormatter = currencyFormatter.format(item.price,{'locale':'id_ID'})
                let totalItemPriceFormatter = currencyFormatter.format(totalItem,{'locale':'id_ID'})
                waLink += productName+"%20"+item.numProduct+'%20x%20'+itemPriceFormatter+'%20=%20'+totalItemPriceFormatter+"%0A"
                console.log(waLink)
            })
            // if(checkcart == null)
            // let text = product.name.split(' ').join('%20');
            // let waLink = this.state.waLink+text;
            this.setState({
                ...this.state,
                cart:carts,
                total:total,
                waLink:waLink
            });
            console.log(this.state.cart)
        }catch(err){
            console.log(err)
        }
    }
    handleCartSend = () => {
        console.log(this.state.cart)
    }

    render(){
        return(
            <div>
                <div className="container col-md-7">
                    <div className="row pt-3 mt-5 px-4 px-md-0">
                        { this.state.products.map((product,i)=>{
                            let price=currencyFormatter.format(product.price,{locale:'id_ID'})
                            return (
                                <ProductItem
                                    image={product.ProductImages[0].path}
                                    name={product.name}
                                    description={product.description}
                                    price={price}
                                    productHanldeClick={(e)=>this.productHanldeClick(e,product)}
                                    indexNum={i}
                                    key={i} />
                            );
                        }) }
                    </div>
                </div>
                <CartComponent cart={this.state.cart} total={this.state.total} waLink={this.state.waLink} />
            </div>
        )
    }
}

export default HomePage;