import React from 'react';
import { Route } from 'react-router-dom';

import HomePage from './pages/HomePage';

// Scss
import './scss/App.scss';
class App extends React.Component
{
    render(){
        return(
            <div>
                <div className="py-3 navbar-top">
                    <center><h4 className="mb-0">KIBA Store</h4></center>
                </div>
                <Route exact path="/" component={HomePage} />
            </div>
        )
    }
}

export default App;